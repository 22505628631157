import request from "@/utils/request";
/*
 * 获取列表
 */
export function getPageList(params) {
  return request({
    url: "/honor/getPageList",
    method: "GET",
    params,
  });
}
/*
 * 添加
 */
export function addHonor(data) {
  return request({
    url: "/honor",
    method: "POST",
    data,
  });
}
/*
 * 编辑
 */
export function editHonor(data) {
  return request({
    url: "/honor",
    method: "PUT",
    data,
  });
}
/*
 * 删除
 */
export function delHonor(data) {
  return request({
    url: `/honor/del/${data.codes}`,
    method: "DELETE",
  });
}
/*
 * 详情
 */
export function getInfo(params) {
  return request({
    url: `/honor/info/${params.code}`,
    method: "GET",
  });
}
