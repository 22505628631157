<!-- 处分 -->
<template>
  <div class="honor-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '处分',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="title-box">
      <span class="react"></span>
      <span class="title">个人处分情况</span>
    </div>
    <van-list
      class="list"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="handleLoad"
      v-if="list && list.length"
    >
      <van-cell
        v-for="(item, i) in list"
        :key="i"
        :title="item.title"
        @click="handleClick(item)"
        is-link
        >{{ item.createTime }}</van-cell
      >
    </van-list>
    <van-empty v-else description="暂无数据" />
  </div>
</template>

<script>
import { getPageList } from "@/api/honorPunish/index.js";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {
      params: {
        pageNum: 1,
        pageSize: 10,
        type: "0",
        isSelf: 1, //	是否只查自己的0-否1-是
      },
      list: null,
      loading: false,
      finished: false,
      total: 0,
    };
  },

  components: {TopWrapper},
  computed: {},

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      getPageList(this.params)
        .then((res) => {
          if (this.params.pageNum == 1) {
            this.list = res.data.records;
          } else {
            this.list = this.list.concat(res.data.records);
          }
          this.params.pageNum++;
          if (this.list.length == res.data.total) {
            this.finished = true;
            this.loading = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleLoad() {
      this.getList();
    },
    handleClick(item) {
      this.$router.push({
        path: `/honorPunishDetail/${item.honorCode}`,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.honor-page {
  min-height: 100%;
  background: #fafafa;
  box-sizing: border-box;
  .title-box {
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
    .react {
      margin-right: 0.12rem;
      width: 0.06rem;
      height: 0.28rem;
      background: #0094ee;
      border-radius: 0.03rem;
    }
    .title {
      font-weight: 500;
      color: #333333;
      font-size: 0.3rem;
      line-height: 0.28rem;
    }
  }
  .list {
    margin-top: 0.3rem;
    min-height: calc(100vh - 1.2rem);
    background: #fff;
  }
  ::v-deep .van-cell__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
